<template>
  <div class="wrapper flex flex-gap flex-middle relative" :class="computedWrapperClass">
    <div
      class="relative preview background-cover flex-middle flex-center cursor-pointer"
      @click.prevent.stop="openMediaManager">
      <div
        v-show="isMediaSelected"
        class="media-preview absolute-cover"
        :style="`background-image: url('${value}')`"></div>
      <icon v-show="!isMediaSelected" icon="image" size="3" theme="gray"></icon>
    </div>

    <div class="actions flex-column flex-between gap-m">
      <form-button
        v-if="isMediaSelected"
        text="core.form.mediaUrl.changeCta"
        theme="gray-5"
        icon="refresh"
        @click.prevent.stop="openMediaManager"></form-button>
      <form-button
        v-if="!isMediaSelected"
        text="core.form.mediaUrl.selectCta"
        theme="success"
        icon="check"
        @click.prevent.stop="openMediaManager"></form-button>
      <div class="input-wrapper">
        <form-input
          v-bind="mediaUrlInputAttrs"
          ref="inputElement"
          v-model="value"
          theme-style="minimal"
          icon="link"
          style="max-width: 100%"
          :type="'text'"
          :wrapper-padding="false"
          placeholder="media.formElements.mediaUrlInputPlaceholder"
          class="url-input field__element"
          :label="false"
          @focus="isFocused = true"
          @blur="isFocused = false" />
      </div>
    </div>
  </div>
  <modal :title="false"
         :show-footer="false"
         :show="isSelectingMedia"
           @modal:closed="isSelectingMedia = false"
         :full="true">
    <media-manager
      v-bind="mediaManagerProps"
      :media-select="true"
      @media:item-selected="setSelectedMedia"></media-manager>
  </modal>
</template>

<script>
import SimpleInputMixin from "@/client/extensions/mixins/baseComponents/form/controls/input/simpleInput.js";

export default {
  mixins: [SimpleInputMixin],
  props: {
    class: {
      default: "",
    },
    placeholder: {
      default: "test",
    },
    mediaManagerProps: {
      type: Object,
      default: {},
    }
  },
  setup(props) {},
  data() {
    return {
      isSelectingMedia: false,
    };
  },
  computed: {
    computedWrapperClass() {
      let localClass = this.class;
      let exploded = localClass.split(" ");
      let result = exploded.filter((className) => {
        return className !== "field__element";
      });

      return result.join(" ");
    },
    isMediaSelected() {
      return this.value && this.value !== "";
    },
    mediaUrlInputAttrs() {
      let result = { ...this.inputAttrs };
      delete result.legend;
      return result;
    },
  },
  methods: {
    openMediaManager() {
      this.isSelectingMedia = false;
      this.isSelectingMedia = true;
    },
    setSelectedMedia(data) {
      this.isSelectingMedia = false;
      this.value = data.item.url;
    },
  },
};
</script>

<style scoped lang="scss">
.preview {
  --image-url-preview-width: 200px;
  width: var(--image-url-preview-width);
  flex: 0 0 var(--image-url-preview-width);
  height: calc(var(--image-url-preview-width) * 9 / 16);
  border-inline-end: 1px solid var(--c-gray-2);
  background-color: var(--c-gray-1);
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1) inset;
  border-radius: var(--border-radius-sharp) 0 0 var(--border-radius-sharp);
  @media screen and (max-width: 767px) {
    width: 150px;
    flex: 0 0 150px;
    height: calc(150px * 9 / 16);
  }
}

.media-preview {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.url-input {
  padding-inline: var(--margin-s);
}
</style>
